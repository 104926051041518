<template>
  <div class="container round px-0">
    <div class="page-header mb-1">
      <strong>ตั้งค่าเลขจ่ายครึ่ง</strong>
    </div>
    <div v-if="roundId" class="card">
      <RoundCardHeader />
      <div class="card-body">
        <div class="row">
          <div class="col-md-4" v-for="bet in marketOpenBets" :key="bet.code">
            <PayHalfNumberList :bet="bet" :data="round" @reload="getRoundSettingNumbers" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import LottoService from '@/services/lottoService'
import RoundCardHeader from './components/RoundCardHeader'
import { marketOpenBets } from "@/configs/market.config"
import PayHalfNumberList from './components/PayHalfNumberList'

export default {
  name: 'CloseNumbers',
  components: {
    RoundCardHeader,
    PayHalfNumberList
  },
  data() {
    return {
      round: null
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    }
  },
  watch: {
    roundId() {
      if(this.roundId) {
        this.getRoundSettingNumbers()
      }
    }
  },
  methods: {
    getRoundSettingNumbers() {
      LottoService.getRoundSettingNumbers(this.roundId)
      .then((response) => {
        console.log('response', response)
        if(response.success) {
          this.round = response.data
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.$router.push({name: 'MarketDashboard'})
        })
      })
    }
  },
  mounted() {
    this.getRoundSettingNumbers()
  }
}
</script>
